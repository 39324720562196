/*
Template Name: AdminHub - Tailwind CSS 3 Admin Layout & UI Kit Template
Version: 1.0
Author: MyraStudio
File: Icons Css File
*/
@import url("https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@2.47.0/tabler-icons.min.css");
/* fallback */
@font-face {
  font-family: "Material Symbols Rounded";
  font-style: normal;
  font-weight: 100 700;
  src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v158/sykg-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190Fjzag.woff2) format("woff2");
}
.material-symbols-rounded {
  font-family: "Material Symbols Rounded";
  font-style: normal;
  line-height: 1;
  width: 1em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-variation-settings: "FILL" 0;
}